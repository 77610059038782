export function isEntireValueMatchingRegex(value: string, regex: RegExp) {
    let isValid = false;
    if (value) {
        const matchArray = value.match(regex);
        if (matchArray !== null) {
            isValid = value === matchArray[0];
        }
    }
    return isValid;
}
