import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@epo/epods-react-components';
import { useTranslation } from 'react-i18next';

import './fileList.css';

interface Props {
    documents: { documentDescription?: string; fileName: string; pagesCount?: string; fileId: string }[];
    deleteFile?: (id: string) => any;
    deleteOption: boolean;
}

const FileList = ({ documents, deleteFile, deleteOption }: any) => {
    const { t } = useTranslation();
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="medium" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('FILE_LIST::DESCRIPTION')}</TableCell>
                        <TableCell align="center">{t('FILE_LIST::FILE_NAME')}</TableCell>
                        <TableCell align="center">{t('FILE_LIST::PAGES')}</TableCell>
                        {deleteOption && <TableCell align="center"></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((document: any) => (
                        <TableRow key={document.fileId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="center" component="th" scope="row">
                                {document.documentDescription}
                            </TableCell>
                            <TableCell align="center">{document.fileName}</TableCell>
                            <TableCell align="center">{document.pagesCount ? document.pagesCount : '-'}</TableCell>
                            {deleteOption && (
                                <TableCell align="center">
                                    <Button
                                        className="mr-s"
                                        onClick={() => deleteFile(document.fileId)}
                                        startEnhancer={<DeleteOutlineIcon fontSize="large" />}
                                    ></Button>{' '}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default FileList;
