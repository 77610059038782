import './footerStyle.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/useTypedSelector';

export default function EpoFooter(props: { showOnlyLowerSection?: boolean }) {
    const [t] = useTranslation();
    const { selectedLanguage } = useAppSelector((state) => state.app);

    const en_dps_url =
        'resources/en/data_protection_statement_processing_personal_data_for_the_epo_contingency_upload_service_en.pdf';
    const fr_dps_url =
        'resources/fr/data_protection_statement_processing_personal_data_for_the_epo_contingency_upload_service_fr.pdf';
    const de_dps_url =
        'resources/de/data_protection_statement_processing_personal_data_for_the_epo_contingency_upload_service_de.pdf';

    const [dpsLink, setDpsLink] = useState(en_dps_url);

    useEffect(() => {
        switch (selectedLanguage) {
            case 'En':
                setDpsLink(en_dps_url);
                break;
            case 'Fr':
                setDpsLink(fr_dps_url);
                break;
            case 'De':
                setDpsLink(de_dps_url);
                break;
            default:
                setDpsLink(en_dps_url);
                break;
        }
    }, [selectedLanguage]);

    return (
        <footer className="bg-gray-900">
            <div className="mx-auto w-full max-w-screen-xl">
                {!props.showOnlyLowerSection && (
                    <div className="grid grid-cols-1 gap-8 px-24 py-8 lg:py-12 md:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-xl font-semibold uppercase text-white">
                                {t('EPO_FOOTER::SERVICES_SUPPORT')}
                            </h2>
                            <ul className="text-gray-500 font-medium">
                                <li className="mb-4">
                                    <a
                                        href="https://epo-org-staging.internal.epo.org/en/service-support/faq"
                                        className="text-lg"
                                    >
                                        {t('EPO_FOOTER::FAQ')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="https://epo-org-staging.internal.epo.org/en/service-support/subscription-centre"
                                        className="text-lg"
                                    >
                                        {t('EPO_FOOTER::SUBSCRIPTION_CENTER')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://epo-org-staging.internal.epo.org/en" className="  text-lg">
                                        {t('EPO_FOOTER::HOLIDAYS')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="https://epo-org-staging.internal.epo.org/en/service-support/publications"
                                        className="text-lg"
                                    >
                                        {t('EPO_FOOTER::PUBLICATIONS')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://epo-org-staging.internal.epo.org/en" className="  text-lg">
                                        {t('EPO_FOOTER::ORDERING')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://epo-org-staging.internal.epo.org/en" className="  text-lg">
                                        {t('EPO_FOOTER::GLOSSARY')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="text-gray-500  font-medium space-y-12">
                                <li className="mb-4">
                                    <a
                                        href="https://epo-org-staging.internal.epo.org/en/news-events/press-centre-test"
                                        className="text-2xl"
                                    >
                                        {t('EPO_FOOTER::PRESS_CENTER')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="https://epo-org-staging.internal.epo.org/en/national-offices-centre"
                                        className="text-2xl"
                                    >
                                        {t('EPO_FOOTER::NATIONAL_OFFICES_CENTER')}
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="https://epo-org-staging.internal.epo.org/en/about-us/procurement"
                                        className="text-2xl"
                                    >
                                        {t('EPO_FOOTER::PROCUREMENT')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="text-gray-500  font-medium space-y-10">
                                <li className="mb-4">
                                    <div className="flex gap-5">
                                        <div>
                                            <img
                                                className="h-10"
                                                src="https://epo-org-staging.internal.epo.org/sites/default/files/2022-09/SoMe-linkedIn.svg"
                                            ></img>
                                        </div>
                                        <div>
                                            <a
                                                href="https://www.linkedin.com/company/163722?trk=tyah"
                                                className="text-xl"
                                            >
                                                {t('EPO_FOOTER::SOCIAL_EPO_OFFICIAL')}
                                            </a>
                                            <span className="text-white"> | </span>
                                            <a
                                                href="https://www.linkedin.com/groups/4109452/profile"
                                                className="  text-xl"
                                            >
                                                {t('EPO_FOOTER::SOCIAL_JOBS')}
                                            </a>
                                            <span className="text-white"> | </span>
                                            <a
                                                href="https://www.linkedin.com/showcase/epo-procurement/"
                                                className="text-xl"
                                            >
                                                Procurement
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="flex gap-5">
                                        <div>
                                            <img
                                                className="h-10"
                                                src="https://epo-org-staging.internal.epo.org/sites/default/files/2022-09/SoMe-twitter.svg"
                                            ></img>
                                        </div>
                                        <div>
                                            <a href="https://twitter.com/EPOorg" className="  text-xl">
                                                {t('EPO_FOOTER::SOCIAL_EPO_OFFICIAL')}
                                            </a>
                                            <span className="text-white"> | </span>
                                            <a href="https://twitter.com/EPOjobs" className="  text-xl">
                                                {t('EPO_FOOTER::SOCIAL_JOBS')}
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="flex gap-5">
                                        <div>
                                            <img
                                                className="h-10"
                                                src="https://epo-org-staging.internal.epo.org/sites/default/files/2022-09/SoMe-facebook.svg"
                                            ></img>
                                        </div>
                                        <div>
                                            <a
                                                href="https://www.facebook.com/europeanpatentoffice"
                                                className="  text-xl"
                                            >
                                                {t('EPO_FOOTER::SOCIAL_EPO_OFFICIAL')}
                                            </a>
                                            <span className="text-white"> | </span>
                                            <a href="https://www.facebook.com/epojobs" className="  text-xl">
                                                {t('EPO_FOOTER::SOCIAL_JOBS')}
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="flex gap-5">
                                        <div>
                                            <img
                                                className="h-10"
                                                src="https://epo-org-staging.internal.epo.org/sites/default/files/2022-09/SoMe-instagram.svg.svg"
                                            ></img>
                                        </div>
                                        <div>
                                            <a
                                                href="https://www.instagram.com/europeanpatentoffice/"
                                                className="  text-xl"
                                            >
                                                {t('EPO_FOOTER::SOCIAL_EPO_OFFICIAL')}
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="flex gap-5">
                                        <div>
                                            <img
                                                className="h-10"
                                                src="https://epo-org-staging.internal.epo.org/sites/default/files/2022-10/SoMe-youtube.svg"
                                            ></img>
                                        </div>
                                        <div>
                                            <a href="https://www.youtube.com/user/EPOfilms" className="  text-xl">
                                                {t('EPO_FOOTER::SOCIAL_EPO_OFFICIAL')}
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <div className="flex gap-5">
                                        <div>
                                            <img
                                                className="h-10"
                                                src="https://epo-org-staging.internal.epo.org/sites/default/files/2022-09/SoMe-tiktok.svg"
                                            ></img>
                                        </div>
                                        <div>
                                            <a href="#" className="  text-xl">
                                                {t('EPO_FOOTER::SOCIAL_EPO_OFFICIAL')}
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                <div className="px-24 py-6 bg-gray-700 md:flex md:items-center md:justify-between">
                    <div className="grid grid-col-1 mt-4 space-y-6 lg:space-y-0 lg:flex lg:space-x-14 md:mt-0">
                        <a href={dpsLink} className="text-sm">
                            {t('EPO_FOOTER::INFO_BAR::DATA_PROTECTION')}
                        </a>
                        <a href="https://www.epo.org/footer/legal-notice.html" className="text-sm">
                            {t('EPO_FOOTER::INFO_BAR::LEGAL_NOTICE')}
                        </a>
                        <a href="https://www.epo.org/applying/online-services/security.html" className="text-sm">
                            {t('EPO_FOOTER::INFO_BAR::SECURITY')}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
