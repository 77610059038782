import { configureStore } from '@reduxjs/toolkit';
import appSlice from '../features/appSlice';
import loginSlice from '../features/loginSlice';
import UploadSlice from '../features/uploadSlice';
import SubmissionsSlice from '../features/submissionsSlice';

const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        login: loginSlice.reducer,
        upload: UploadSlice.reducer,
        submissions: SubmissionsSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
