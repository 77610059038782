import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UploadState {
    isUploading?: boolean;
    hasError?: boolean;
    uploadedFiles: object[];
    submittedData: any;
}

export const initialState: UploadState = {
    isUploading: false,
    hasError: false,
    uploadedFiles: [],
    submittedData: null,
};

const UploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setUploadedFiles: (state: any, action: PayloadAction<object>) => {
            return {
                ...state,
                uploadedFiles: state.uploadedFiles ? [...state.uploadedFiles, action.payload] : action.payload,
            };
        },
        removeFile: (state: any, action: PayloadAction<string>) => {
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.filter((file: any) => file.fileId !== action.payload),
            };
        },
        setSubmittedData: (state: any, action: PayloadAction<object>) => {
            return {
                ...state,
                submittedData: action.payload,
            };
        },
    },
});

export const uploadActions = UploadSlice.actions;
export default UploadSlice;
