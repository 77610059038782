import { isEntireValueMatchingRegex } from './ValidatorCommonUtils';

const epNumberRegex = /^EP[0-9]{8}\.[0-9]{1}$/g;

export function validateEPNumber(epNumber: string): boolean {
    let isValid: boolean = false;
    if (isEPNumberFormatValid(epNumber)) {
        const epNumberArray: number[] = Array.from(epNumber).map((x) => Number(x));
        const sequenceNumber = epNumberArray.slice(2, 10);

        //multiply each character alternately by 2
        let index = 0;
        const sequenceNumberSumAndMul: number = sequenceNumber.reduce((accumulator, currentValue) => {
            if (index % 2 === 0) {
                accumulator += sumValueDigits(currentValue);
            } else {
                accumulator += sumValueDigits(currentValue * 2);
            }

            index++;
            return accumulator;
        }, 0);

        // Subtract the last digit of the result from 10, if the sigit was 0 then check digit is 0
        const lastDigit = sequenceNumberSumAndMul % 10;
        const checkDigit = lastDigit === 0 ? 0 : 10 - lastDigit;
        const checkDigitEPNumber = epNumberArray.splice(-1)[0];
        isValid = checkDigit === checkDigitEPNumber;
    }

    return isValid;
}

function sumValueDigits(value: number): number {
    const valueArray: number[] = Array.from(String(value), (num) => Number(num));
    return valueArray.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
}

function isEPNumberFormatValid(epNumber: string): boolean {
    return isEntireValueMatchingRegex(epNumber, epNumberRegex);
}
