import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    data: object[];
}

const DataField = ({ data }: Props) => {
    const { t } = useTranslation();
    return (
        <Grid display="flex" justifyContent="center" alignItems="center" container spacing={2}>
            {data.map((item: any) => (
                <>
                    <Grid item xs={4}>
                        <Typography fontSize="16px">{t(item.label)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {item.value}
                    </Grid>
                </>
            ))}
        </Grid>
    );
};

export default DataField;
