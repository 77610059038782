import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from '@epo/epods-react-components/lib/Components/Button';

import { useTranslation } from 'react-i18next';
import { loginActions } from '../../../features/loginSlice';
import { BACKEND_URL } from '../../../variables';
import EmailSignInPage from '../EmailSignInPage';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';

const MainLoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { selectedLoginType } = useAppSelector((state) => state.login);
    const { setSelectedLoginType } = loginActions;

    const redirectToOkta = async (e: any) => {
        dispatch(setSelectedLoginType(e.target.id));
    };

    const selectLoginType = (e: any) => {
        dispatch(setSelectedLoginType(e.target.id));
    };
    const callbackUrl = window.location.href.endsWith('/') ? window.location.href : `${window.location.href}/`;

    return (
        <>
            {!selectedLoginType && (
                <Box>
                    <Typography fontWeight={700} variant="h4">
                        {t('MAIN_LOGIN_PAGE::TITLE')}
                    </Typography>
                    <Typography sx={{ marginTop: '3rem', fontSize: '14px' }}>
                        {t('MAIN_LOGIN_PAGE::WELCOME_TEXT')}
                    </Typography>
                    <Typography sx={{ marginTop: '3rem', fontSize: '14px' }}>
                        {t('MAIN_LOGIN_PAGE::EPO_ACCOUNT_SIGN_IN')}
                    </Typography>
                    <Box textAlign="center" marginTop="2rem">
                        <a
                            style={{ textDecoration: 'none' }}
                            href={`${BACKEND_URL}/loginsso?callbackUrl=${callbackUrl}upload-page`}
                        >
                            <Button id="okta" onClick={redirectToOkta}>
                                {t('MAIN_LOGIN_PAGE::OKTA_LOGIN_BUTTON')}
                            </Button>
                        </a>
                    </Box>
                    <Typography sx={{ marginTop: '3rem', fontSize: '14px' }}>
                        {t('MAIN_LOGIN_PAGE::EMAIL_SIGN_IN')}
                    </Typography>
                    <Box textAlign="center" marginTop="2rem">
                        <Button id="oneHour" onClick={selectLoginType}>
                            {t('MAIN_LOGIN_PAGE::ONE_HOUR_LOGIN_BUTTON')}
                        </Button>
                    </Box>
                </Box>
            )}
            {selectedLoginType === 'oneHour' && <EmailSignInPage />}
        </>
    );
};

export default MainLoginPage;
