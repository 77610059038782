import { environment } from './utils/constants';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const _dwb_env_ = (window as any)._dossier_workbench_env_;

const BACKEND_URL = `/${environment.namespace}/${environment.backendContext}/api`;

const PUBLIC_URL = _dwb_env_ ? _dwb_env_.REACT_APP_PUBLIC_URL : process.env.PUBLIC_URL;

const DEV_TOKEN = process.env.REACT_APP_DEV_TOKEN;

export { PUBLIC_URL, BACKEND_URL, IS_PRODUCTION, IS_DEVELOPMENT, DEV_TOKEN };
