import React, { createRef, useEffect, useRef, useState } from 'react';
import {
    Button,
    Column,
    DataTable,
    Datepicker,
    DropdownButton,
    FormControl,
    Input,
    Notification,
} from '@epo/epods-react-components';
import { Box, Checkbox, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { DeleteOutline } from '@epo/epods-react-components/lib/Icons/DeleteOutline';

import { BACKEND_URL } from '../../../variables';

import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { submissionsActions } from '../../../features/submissionsSlice';
import './style.css';
import useDateFormatter from '../../../hooks/useDateFormatter/useDateFormatter';
import useAxios from '../../../hooks/useAxios/useAxios';

const statusValues = ['NEW', 'PROCESSED', 'PENDING_REVIEW', 'EDITED', 'MANUALLY_PROCESSED'];

interface SubmissionsObject {
    accessCode: string;
    address: string;
    status: string;
    reviewMessage: string;
    applicationNumber: string;
    company: string;
    email: string;
    name: string;
    surname: string;
    phoneNumber: string;
    timestamp: string;
    userReference: string;
    documents: object;
    signingName: string;
    signingFunction: string;
    place: string;
    textString: string;
}

const SubmissionsListPage = (args: any) => {
    const dispatch = useAppDispatch();
    const { submissions, viewFilters, filteredData, isFiltered, filterByState } = useAppSelector(
        (state) => state.submissions,
    );
    const {
        setSubmissions,
        setSelectedSubmission,
        setSelectViewFilter,
        setFilterByState,
        clearFilterByState,
        setFilteredData,
        clearFilteredData,
    } = submissionsActions;
    const [dateRangeError, setDateRangeError] = useState(false);
    const dt = createRef();
    const formatter = useDateFormatter();
    const axios = useAxios();

    useEffect(() => {
        axios
            .get(`${BACKEND_URL}/internal/documents`)
            .then((res) => {
                let newData: any = [];
                res.data.map((submission: any) => {
                    if (submission.metadata !== null && submission.timestamp !== null) {
                        const { timestamp, accessCode, status, reviewMessage } = submission;
                        const { contactDetails, uploadDetails, documents, signature } = submission.metadata;
                        newData.push({
                            accessCode,
                            timestamp,
                            ...contactDetails,
                            ...uploadDetails,
                            documents,
                            ...signature,
                            status,
                            reviewMessage,
                        });
                    } else {
                        return;
                    }
                });
                newData.sort((a: any, b: any) => {
                    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                });
                newData = newData.map((data: any) => {
                    return {
                        ...data,
                        timestamp: formatter.formatDate(data.timestamp),
                    };
                });

                const submissionsDispatch = dispatch(setSubmissions(newData));

                if (isFiltered) {
                    filterData(submissionsDispatch.payload);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const linkBodyTemplate = (submission: SubmissionsObject) => (
        <Link
            onClick={() => dispatch(setSelectedSubmission(submission))}
            style={{ color: 'black' }}
            to={'/submissions/details'}
        >
            {submission.userReference}
        </Link>
    );

    const getFiltersData = (e: any) => {
        let currentStateFilter;
        if (e.target.id === 'userReference' || e.target.id === 'companyOrOrganisation') {
            currentStateFilter = {
                ...filterByState,
                [e.target.id]: e.target.value.trimStart(),
            };
        } else {
            currentStateFilter = {
                ...filterByState,
                [e.target.id ? e.target.id : e.target.name]: e.target.value.trim(),
            };
        }

        console.log('currentStateFilter', currentStateFilter);

        dispatch(setFilterByState(currentStateFilter));
    };

    const clearFilters = (e: any) => {
        e.preventDefault();
        dispatch(clearFilterByState());
        dispatch(clearFilteredData());
    };

    const filterData = (submissions: any) => {
        const filtered = submissions
            .filter((submission: any) => {
                return filterByState.userReference
                    ? submission.userReference === filterByState.userReference.trim()
                    : submission;
            })
            .filter((submission: any) => {
                return filterByState.applicationNumber
                    ? filterByState.applicationNumber === submission.applicationNumber
                    : submission;
            })
            .filter((submission: any) => {
                const submissionDate = formatter.parseString(submission.timestamp);
                const startDate: Date = filterByState.uploadFrom ? new Date(filterByState.uploadFrom) : undefined;
                if (startDate) {
                    startDate.setHours(0, 0, 0, 0);
                }
                const endDate: Date = filterByState.uploadTo ? new Date(filterByState.uploadTo) : undefined;
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }
                return startDate && endDate
                    ? submissionDate.toMillis() >= startDate.getTime() && submissionDate.toMillis() <= endDate.getTime()
                    : submission;
            })
            .filter((submission: any) => {
                return filterByState.accessCode ? filterByState.accessCode === submission.accessCode : submission;
            })
            .filter((submission: any) => {
                return filterByState.companyOrOrganisation
                    ? filterByState.companyOrOrganisation.trim() === submission.company
                    : submission;
            })
            .filter((submission: any) => {
                return filterByState.status ? filterByState.status === submission.status : submission;
            });

        if (
            (filterByState.uploadFrom && !filterByState.uploadTo) ||
            (filterByState.uploadTo && !filterByState.uploadFrom)
        ) {
            return setDateRangeError(true);
        }

        return dispatch(setFilteredData(filtered));
    };

    const onSubmitHandler = (e: any) => {
        e.preventDefault();
        return filterData(submissions);
    };

    const filterByFilters = () => (
        <Box sx={{ backgroundColor: 'white', padding: '2rem', borderRadius: '4px', width: '300px' }}>
            <FormControl label={<>User Reference</>}>
                <Input
                    name="userReference"
                    id="userReference"
                    data-test="userReference"
                    aria-label="userReference"
                    onChange={getFiltersData}
                    value={filterByState.userReference}
                />
            </FormControl>
            <FormControl label={<>EP Application Number / IA Number</>}>
                <Input
                    {...args}
                    name="applicationNumber"
                    id="applicationNumber"
                    data-test="applicationNumber"
                    aria-label="applicationNumber"
                    onChange={getFiltersData}
                    value={filterByState.applicationNumber}
                />
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: ' center', justifyContent: 'space-between', gap: '10px' }}>
                <FormControl label={<>Upload from </>}>
                    <Datepicker
                        name="uploadFrom"
                        id="uploadFrom"
                        data-test="uploadFrom"
                        aria-label="uploadFrom"
                        maxDate={filterByState.uploadTo ? new Date(filterByState.uploadTo) : new Date()}
                        value={filterByState.uploadFrom && new Date(filterByState.uploadFrom)}
                        onChange={({ date }) => {
                            date instanceof Date &&
                                dispatch(
                                    setFilterByState({
                                        ...filterByState,
                                        uploadFrom: date.getTime(),
                                    }),
                                );
                        }}
                    />
                </FormControl>
                <FormControl label={<>Upload to </>}>
                    <Datepicker
                        name="uploadTo"
                        id="uploadTo"
                        data-test="uploadTo"
                        aria-label="uploadTo"
                        value={filterByState.uploadTo && new Date(filterByState.uploadTo)}
                        maxDate={new Date()}
                        minDate={filterByState.uploadFrom ? new Date(filterByState.uploadFrom) : new Date(+0)}
                        onChange={({ date }) => {
                            date instanceof Date &&
                                dispatch(
                                    setFilterByState({
                                        ...filterByState,
                                        uploadTo: date.getTime(),
                                    }),
                                );
                        }}
                    />
                </FormControl>
            </Box>
            {dateRangeError && (
                <Notification
                    theme="negative"
                    onClose={() => setDateRangeError(false)}
                    text="You need to select a date range."
                />
            )}
            <FormControl label={<>EPO reference number </>}>
                <Input
                    {...args}
                    name="accessCode"
                    id="accessCode"
                    data-test="accessCode"
                    aria-label="accessCode"
                    onChange={getFiltersData}
                    value={filterByState.accessCode}
                />
            </FormControl>
            <FormControl label={<>Company or Organisation </>}>
                <Input
                    {...args}
                    name="companyOrOrganisation"
                    id="companyOrOrganisation"
                    data-test="companyOrOrganisation"
                    aria-label="companyOrOrganisation"
                    onChange={getFiltersData}
                    value={filterByState.companyOrOrganisation}
                />
            </FormControl>
            <FormControl label={<>Status</>}>
                <RadioGroup
                    {...args}
                    name="status"
                    id="status"
                    label="Status"
                    data-test="status"
                    aria-label="status"
                    onChange={getFiltersData}
                    value={filterByState.status}
                >
                    <div className="grid grid-cols-2">
                        {statusValues.map((status) => {
                            return (
                                <div key={status}>
                                    <FormControlLabel id="status" value={status} control={<Radio />} label={status} />
                                </div>
                            );
                        })}
                    </div>
                </RadioGroup>
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button size="mini" theme="tertiary-phantom" startEnhancer={<DeleteOutline />} onClick={clearFilters}>
                    Clear All
                </Button>
                <Button onClick={onSubmitHandler} size="mini">
                    Apply
                </Button>
            </Box>
        </Box>
    );

    const headerBar = (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
                <DropdownButton
                    icon="settings"
                    theme="tertiary"
                    multiselect
                    styleListContainer={{
                        width: 'auto',
                        zIndex: 100,
                    }}
                    onItemSelect={(data: any) => {
                        const { item } = data;
                        dispatch(setSelectViewFilter(item.id));
                    }}
                    items={viewFilters}
                >
                    Change View
                </DropdownButton>
                <DropdownButton
                    icon="filter_funnel"
                    theme="tertiary"
                    styleListContainer={{
                        width: '300px',
                    }}
                    nestedComponent={filterByFilters()}
                >
                    Filter by
                </DropdownButton>
            </Box>
        </>
    );

    return (
        <div style={{ minHeight: '350px' }}>
            <DataTable
                {...args}
                header={headerBar}
                dataKey={'accessCode'}
                ref={dt}
                value={
                    filteredData.length > 0 || (filteredData.length === 0 && isFiltered) ? filteredData : submissions
                }
                responsive
                emptyMessage="No records found."
                rowsPerPage={10}
                paginator
            >
                {viewFilters.map((filter) => {
                    if (filter.id === 'allColumns') {
                        return null;
                    } else if (filter.id === 'userReference') {
                        return (
                            filter.checked && (
                                <Column
                                    key={filter.id}
                                    field={filter.id}
                                    header={filter.label}
                                    body={linkBodyTemplate}
                                />
                            )
                        );
                    } else {
                        return (
                            filter.checked && (
                                <Column
                                    sortable={filter.id === 'timestamp'}
                                    key={filter.id}
                                    field={filter.id}
                                    header={filter.label}
                                />
                            )
                        );
                    }
                })}
            </DataTable>
        </div>
    );
};
export default SubmissionsListPage;
