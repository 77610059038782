import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FileList from '../UploadPage/components/FileList';
import DataField from '../../components/DataField';
import { useAppSelector } from '../../../hooks/useTypedSelector';
import useDateFormatter from '../../../hooks/useDateFormatter/useDateFormatter';

const SubmissionConfirmationPage = () => {
    const { submittedData } = useAppSelector((state) => state.upload);
    const { t } = useTranslation();
    const { accessCode, timestamp } = submittedData;
    const { contactDetails, uploadDetails, documents, signature } = submittedData.metadata;
    const formatter = useDateFormatter();

    return (
        <Box>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {t('UPLOAD_CONFIRMATION_PAGE::TITLE')}
            </Typography>
            <Typography sx={{ fontSize: '1.5rem', margin: '3rem 0' }}>
                {t('UPLOAD_CONFIRMATION_PAGE::NOTIFICATION_MESSAGE')}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('UPLOAD_CONFIRMATION_PAGE::TIMESTAMP_ACCESS_CODE::TITLE')}
            </Typography>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::TIMESTAMP_ACCESS_CODE::UPLOAD_TIMESTAMP',
                            value: formatter.formatDate(timestamp),
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::TIMESTAMP_ACCESS_CODE::ACCESS_CODE',
                            value: accessCode,
                        },
                    ]}
                />
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::TITLE')}
            </Typography>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::EMAIL',
                            value: contactDetails.email,
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::FIRST_NAME',
                            value: contactDetails.name,
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::LAST_NAME',
                            value: contactDetails.surname,
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::TELEPHONE_NUMBER',
                            value: contactDetails.phoneNumber,
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::COMPANY_OR_ORGANISATION',
                            value: contactDetails.company,
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::CONTACT_DETAILS::ADDRESS',
                            value: contactDetails.address,
                        },
                    ]}
                />
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('UPLOAD_CONFIRMATION_PAGE::PACKAGE_DETAILS::TITLE')}
            </Typography>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::PACKAGE_DETAILS::USER_REFERENCE',
                            value: uploadDetails.userReference,
                        },
                        {
                            label: 'UPLOAD_CONFIRMATION_PAGE::PACKAGE_DETAILS::EP_APPLICATION_OR_IA_NUMBER',
                            value: uploadDetails.applicationNumber,
                        },
                    ]}
                />
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('UPLOAD_CONFIRMATION_PAGE::ATTACHED_DOCUMENTS')}
            </Typography>
            <hr />
            <FileList documents={documents} deleteOption={false} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 4 }}>
                <div>
                    <Typography variant="h4" fontWeight={600}>
                        {t('SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::TITLE')}
                    </Typography>
                </div>
            </Box>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_NAME',
                            value: signature.signingName,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_FUNCTION',
                            value: signature.signingFunction,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_PLACE',
                            value: signature.place,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_STRING_SIGN',
                            value: signature.textString,
                        },
                    ]}
                />
            </Box>
            <hr />
        </Box>
    );
};

export default SubmissionConfirmationPage;
