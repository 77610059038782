import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    selectedLanguage: string;
    isLoggedIn: boolean;
    isLoading: boolean;
}

export const initialState: AppState = {
    selectedLanguage: localStorage.getItem('language') || 'En',
    isLoggedIn: false,
    isLoading: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        resetState: (state: AppState) => {
            state = initialState;
        },
        setSelectedLanguage: (state: AppState, action: PayloadAction<string>) => {
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        },
        setIsLoading: (state: AppState, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
    },
});

export const appActions = appSlice.actions;
export default appSlice;
