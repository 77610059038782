import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SubmissionObject {
    accessCode: string;
    address: string;
    applicationNumber: string;
    company: string;
    status: string;
    reviewMessage: string;
    email: string;
    name: string;
    surname: string;
    phoneNumber: string;
    timestamp: string;
    userReference: string;
    documents: object;
    signingName: string;
    signingFunction: string;
    place: string;
    textString: string;
}

interface ViewFilter {
    id: string;
    label: string;
    checked: boolean;
}

interface StateFilter {
    uploadFrom: number;
    uploadTo: number;
    userReference: string;
    applicationNumber: string;
    accessCode: string;
    companyOrOrganisation: string;
    status: string;
}

export interface SubmissionsState {
    hasError?: boolean;
    submissions: object[];
    viewFilters: ViewFilter[];
    filterByState: StateFilter;
    selectedSubmission: SubmissionObject;
    filteredData: object[];
    isFiltered: boolean;
}

export const initialState: SubmissionsState = {
    hasError: false,
    submissions: [],
    selectedSubmission: {
        accessCode: '',
        status: '',
        reviewMessage: '',
        address: '',
        applicationNumber: '',
        company: '',
        email: '',
        name: '',
        surname: '',
        timestamp: '',
        userReference: '',
        phoneNumber: '',
        documents: {},
        signingName: '',
        signingFunction: '',
        place: '',
        textString: '',
    },
    viewFilters: [
        { id: 'allColumns', label: 'All Columns', checked: false },
        { id: 'userReference', label: 'User Reference', checked: true },
        { id: 'applicationNumber', label: 'EP Application Number / IA Number', checked: true },
        { id: 'timestamp', label: 'Upload Date', checked: true },
        { id: 'accessCode', label: 'EPO reference number', checked: true },
        { id: 'name', label: 'First Name', checked: false },
        { id: 'surname', label: 'Last Name', checked: false },
        { id: 'company', label: 'Company or Organisation', checked: true },
        { id: 'status', label: 'Status', checked: true },
    ],
    filterByState: {
        uploadFrom: undefined,
        uploadTo: undefined,
        userReference: '',
        applicationNumber: '',
        accessCode: '',
        companyOrOrganisation: '',
        status: '',
    },
    filteredData: [],
    isFiltered: false,
};

const SubmissionsSlice = createSlice({
    name: 'submissions',
    initialState,
    reducers: {
        setSubmissions: (state: any, action: PayloadAction<object>) => {
            return {
                ...state,
                submissions: action.payload,
            };
        },
        setSelectedSubmission: (state: any, action: PayloadAction<object>) => {
            return {
                ...state,
                selectedSubmission: action.payload,
            };
        },
        setFilteredData: (state: any, action: PayloadAction<object[]>) => {
            return {
                ...state,
                filteredData: action.payload,
                isFiltered: true,
            };
        },
        clearFilteredData: (state: any) => {
            return {
                ...state,
                filteredData: [],
                isFiltered: false,
            };
        },
        setFilterByState: (state: any, action: PayloadAction<object>) => {
            return {
                ...state,
                filterByState: action.payload,
            };
        },
        clearFilterByState: (state: any) => {
            return {
                ...state,
                filterByState: {
                    uploadFrom: undefined,
                    uploadTo: undefined,
                    userReference: '',
                    applicationNumber: '',
                    accessCode: '',
                    companyOrOrganisation: '',
                    status: '',
                },
                isFiltered: false,
            };
        },
        setSelectViewFilter: (state: any, action: PayloadAction<string>) => {
            if (action.payload === 'allColumns') {
                return {
                    ...state,
                    viewFilters: state.viewFilters.map((filter: any) => {
                        return {
                            ...filter,
                            checked: !state.viewFilters[0].checked,
                        };
                    }),
                };
            }

            if (action.payload !== 'allColumns' && state.viewFilters[0].checked) {
                const newFilters = state.viewFilters.map((filter: any) => {
                    return filter.id === 'allColumns'
                        ? { ...filter, checked: false }
                        : filter.id === action.payload
                        ? { ...filter, checked: false }
                        : { ...filter };
                });
                return {
                    ...state,
                    viewFilters: newFilters,
                };
            }

            const newFilters = state.viewFilters.map((filter: any) => {
                return filter.id === action.payload ? { ...filter, checked: !filter.checked } : { ...filter };
            });

            return {
                ...state,
                viewFilters: newFilters,
            };
        },
    },
});

export const submissionsActions = SubmissionsSlice.actions;
export default SubmissionsSlice;
