import React, { Fragment, useState, useEffect, useRef, MutableRefObject } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Input, FormControl, Feedback } from '@epo/epods-react-components';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { BACKEND_URL } from '../../../variables';

import { ArrowBack } from '@epo/epods-react-components/lib/Icons/ArrowBack';
import LoginConfirmationPage from '../LoginConfirmationPage';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { loginActions } from '../../../features/loginSlice';
import { appActions } from '../../../features/appSlice';
import { WidgetInstance } from 'friendly-challenge';
import useAxios from '../../../hooks/useAxios/useAxios';

const EmailSignInPage = () => {
    const { isLoading } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [captchaSolution, setCaptchaSolution] = useState('');
    const baseLink = window.location.href.endsWith('/') ? window.location.href : `${window.location.href}/`;
    const widget: MutableRefObject<WidgetInstance> = useRef();
    const captcha = useRef();
    const { setSelectedLoginType } = loginActions;
    const { setIsLoading } = appActions;
    const axios = useAxios();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,6}$/,
                    t('INVALID_EMAIL'),
                )
                .required(t('REQUIRED_FIELD')),
        }),
        onSubmit(values) {
            dispatch(setIsLoading(true));
            axios
                .post(`${BACKEND_URL}/loginemail`, {
                    email: values.email,
                    linkUrl: `${baseLink}upload-page`,
                    captchaToken: captchaSolution,
                })
                .then(() => (setIsFormSubmitted(true), dispatch(setIsLoading(false))))
                .catch(() => (setIsFormSubmitted(false), dispatch(setIsLoading(false)), setHasError(true)));
        },
    });

    const captchaDone = (solution: any) => {
        setCaptchaSolution(solution);
    };

    const captchaError = (err: any) => {
        console.log('There was an error when trying to solve the Captcha.');
        console.log(err);
        setHasError(true);
    };

    const goBackToLoginSelection = () => {
        dispatch(setSelectedLoginType(null));
    };

    useEffect(() => {
        if (!widget.current && captcha.current) {
            widget.current = new WidgetInstance(captcha.current, {
                startMode: 'auto',
                doneCallback: captchaDone,
                errorCallback: captchaError,
                sitekey: 'FCMU6O79QBV5LK09',
                puzzleEndpoint: 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
            });
        }

        return () => {
            if (widget.current != undefined) widget.current.reset();
        };
    }, [captcha]);

    return (
        <Box sx={{ width: 600, margin: 'auto' }}>
            {!isFormSubmitted && (
                <Fragment>
                    <Typography variant="h4" fontWeight={700}>
                        {t('EMAIL_SIGN_IN_PAGE::TITLE')}
                    </Typography>
                    <Typography fontSize="16px" marginTop="3rem">
                        {t('EMAIL_SIGN_IN_PAGE::VALID_EMAIL')}
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl
                            label={<>{t('EMAIL_ADDRESS')}</>}
                            caption={formik.touched.email && (formik.errors.email as string)}
                        >
                            <Input
                                tabIndex={0}
                                type="email"
                                id="email"
                                error={formik.touched.email && !formik.dirty ? true : false}
                                data-test="user-email"
                                aria-label="email-address"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                        </FormControl>
                        <div style={{ maxWidth: '100%' }} ref={captcha} className="frc-captcha" />
                        <Box textAlign="center" marginTop="2rem">
                            <Button isLoading={isLoading} type="submit" disabled={captchaSolution.length == 0}>
                                {t('EMAIL_SIGN_IN_PAGE::SUBMIT')}
                            </Button>
                        </Box>
                    </form>
                    {hasError && (
                        <Box textAlign="center" marginTop="3rem">
                            <Feedback
                                theme="negative"
                                text="Something went wrong. Please try again"
                                onClose={() => setHasError(false)}
                            />
                        </Box>
                    )}
                    <Typography fontSize="16px" marginTop="3rem">
                        {t('ONE_HOUR_VALIDATION_NOTIFICATION')}
                    </Typography>
                    <Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
                        <Button
                            onClick={goBackToLoginSelection}
                            size="mini"
                            theme="tertiary-phantom"
                            startEnhancer={<ArrowBack />}
                        >
                            Go back
                        </Button>
                    </Box>
                </Fragment>
            )}
            {isFormSubmitted && <LoginConfirmationPage oneHourEmail={formik.values.email} />}
        </Box>
    );
};

export default EmailSignInPage;
