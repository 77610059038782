export type Environment = {
    contextRoot: string;
    backendUrl: string;
    projectVersion: string;
    gitUrl: string;
    gitCommit: string;
    gitBranch: string;
    reactAppVersion: string;
    namespace: string;
    baseUri: string;
    backendContext: string;
};

declare const _environment: Environment;
export const environment = _environment;
