import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { Theme, BaseProvider } from '@epo/epods-react-components/lib/Themes';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import store from './store';

import 'sanitize.css/sanitize.css';

// EPO stylesheets
import '@epo/epods-react-components/lib/CSS/base-host.min.css';
import '@epo/epods-react-components/lib/CSS/components.min.css';
import '@epo/epods-react-components/lib/CSS/grid.min.css';
// import '@epo/epods-react-components/lib/CSS/utilities.min.css';
import '@epo/epods-react-components/lib/CSS/fontface-corporate.min.css';
import '@epo/epods-react-components/lib/CSS/fontface-epoicons-full.min.css';
import '@epo/epods-react-components/lib/CSS/iconography-full.min.css';

const engine = new Styletron();

ReactDOM.render(
    <Provider store={store}>
        <StyletronProvider value={engine}>
            <BaseProvider theme={Theme}>
                <App />
            </BaseProvider>
        </StyletronProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
