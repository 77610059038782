import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, Input, Notification } from '@epo/epods-react-components';
import { BACKEND_URL } from '../../../variables';
import { FileUpload } from '@epo/epods-react-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FileList from './components/FileList';
import { useNavigate } from 'react-router-dom';
import { uploadActions } from '../../../features/uploadSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { appActions } from '../../../features/appSlice';
import './uploadPageStyles.css';
import { validateEPNumber } from './utility/EPValidator';
import { isPCTNumberValid } from './utility/PCTValidator';
import useAxios from '../../../hooks/useAxios/useAxios';

interface User {
    email: string;
    name?: string;
    surname?: string;
    phoneNumber?: string;
}

const UploadPage = () => {
    const { uploadedFiles } = useAppSelector((state) => state.upload);
    const { isLoading } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setUploadedFiles, removeFile, setSubmittedData } = uploadActions;
    const applicationNumberRef: any = useRef();
    const { setIsLoading } = appActions;
    const [errMessage, setErrMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [noFileError, setNoFileError] = useState(false);
    const [fileNameError, setFileNameError] = useState(false);
    const [applicationNumber, setApplicationNumber] = useState('');
    const navigate = useNavigate();
    const fileUploadRef: any = useRef();
    const [documentDescription, setDocumentDescription] = useState('');
    const [textStringSign, setTextStringSign] = useState('');
    const axios = useAxios();

    const [state, setState] = useState<User>({
        email: '',
        name: '',
        surname: '',
        phoneNumber: '',
    });

    useEffect(() => {
        const token = window.location.href.split('token=')[1];
        axios
            .get(`${BACKEND_URL}/loginemail/${token}`)
            .then((res) =>
                setState((prevState: User) => {
                    return {
                        ...prevState,
                        email: res.data?.email,
                        name: res.data?.name,
                        surname: res.data?.surname,
                        phoneNumber: res.data?.phone,
                    };
                }),
            )
            .catch(() => navigate('/'));
    }, []);

    const getDocumentDescription = (e: any) => {
        setDocumentDescription(e.target.value);
    };

    const phoneRegex = /[\d\s\-+\(\)\[\]]+/;
    const userReferenceAllowedCharRegex = /[a-zA-Z0-9\-()\/\\.,<>=_"]+/;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: state.email ? state.email : '',
            name: state.name ? state.name : '',
            surname: state.surname ? state.surname : '',
            phoneNumber: state.phoneNumber ? state.phoneNumber : '',
            company: '',
            address: '',
            userReference: '',
            applicationNumber: '',
            documents: [],
            nameSign: '',
            functionSign: '',
            placeSign: '',
            textStringSign: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(t('REQUIRED_FIELD')),
            name: Yup.string().required(t('REQUIRED_FIELD')),
            surname: Yup.string().required(t('REQUIRED_FIELD')),
            phoneNumber: Yup.string()
                .required(t('REQUIRED_FIELD'))
                .matches(phoneRegex, t('INVALID_PHONE'))
                .max(25, t('PHONE_MAX')),
            userReference: Yup.string()
                .required(t('REQUIRED_FIELD'))
                .matches(userReferenceAllowedCharRegex, t('INVALID_REF')),
            applicationNumber: Yup.string().test(
                'validateEPNumberAndPCTNumber',
                t('UPLOAD_PAGE::EP_NUMBER_NOT_VALID'),
                (value) => (value ? validateEPNumber(value) || isPCTNumberValid(value) : true),
            ),
            nameSign: Yup.string().required(t('REQUIRED_FIELD')),
            functionSign: Yup.string().required(t('REQUIRED_FIELD')),
        }),
        onSubmit(values) {
            const data = {
                contactDetails: {
                    email: state.email ? state.email : values.email,
                    name: state.name ? state.name : values.name,
                    surname: state.surname ? state.surname : values.surname,
                    phoneNumber: state.phoneNumber ? state.phoneNumber : values.phoneNumber,
                    company: values.company,
                    address: values.address,
                },
                uploadDetails: {
                    userReference: values.userReference,
                    applicationNumber: values.applicationNumber,
                },
                signature: {
                    signingName: values.nameSign,
                    signingFunction: values.functionSign,
                    place: values.placeSign,
                    textString: values.textStringSign,
                },
                documents: uploadedFiles,
            };
            if (uploadedFiles.length > 0) {
                dispatch(setIsLoading(true));
                axios
                    .post(`${BACKEND_URL}/documents`, data)
                    .then(
                        (res) => (
                            dispatch(setSubmittedData(res.data)),
                            navigate('/upload-confirmation'),
                            dispatch(setIsLoading(false))
                        ),
                    )
                    .catch(
                        (err) => ((dispatch(setIsLoading(false)), setSubmitError(true)), setErrMessage(err.message)),
                    );
            } else {
                setNoFileError(true);
            }
        },
    });

    const fileUploadHandler = (e: any) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', e.files[0]);
        const fileType = e.files[0].name.slice(-4);
        const isFileExist = uploadedFiles.some((file: any) => file.fileName === e.files[0].name);
        const fileSize = e.files[0].size / 1024;
        if (fileSize > 51200) {
            e.files.pop();
            setFileSizeError(true);
            setIsUploading(false);
            return;
        }
        if (isFileExist) {
            e.files.pop();
            setFileNameError(true);
            setIsUploading(false);
            return;
        }

        if (fileType === '.pdf' || fileType === '.xml' || fileType === '.zip') {
            axios
                .post(`${BACKEND_URL}/documents/file`, formData)
                .then(
                    (res) => (
                        dispatch(
                            setUploadedFiles({
                                ...res.data,
                                documentDescription,
                            }),
                        ),
                        setIsUploading(false),
                        setHasError(false),
                        setFileError(false),
                        setDocumentDescription('')
                    ),
                )
                .catch(() => {
                    return setIsUploading(false), setHasError(true);
                });
        } else {
            setFileError(true);
            setIsUploading(false);
        }
        fileUploadRef.current.clear();
    };

    const deleteFile = (id: string) => {
        axios.delete(`${BACKEND_URL}/documents/file/${id}`);
        dispatch(removeFile(id));
    };

    const handlePhoneNumberInput = async (event: any) => {
        const currentInputValue: string = event.target.value;
        const matchedValue = currentInputValue.match(phoneRegex);
        const validInputValue = matchedValue ? matchedValue[0] : '';
        await formik.setFieldValue('phoneNumber', validInputValue, false);
        await formik.validateField('phoneNumber');
    };

    const handleUserReferenceInput = async (event: any) => {
        const currentInputValue: string = event.target.value;
        const matchedValue = currentInputValue.match(userReferenceAllowedCharRegex);
        const validInputValue = matchedValue ? matchedValue[0] : '';
        await formik.setFieldValue('userReference', validInputValue, false);
        await formik.validateField('userReference');
    };

    return (
        <Box>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {t('UPLOAD_PAGE::TITLE')}
            </Typography>
            <Typography variant="h5" sx={{ marginTop: '3rem', marginBottom: '2rem' }}>
                {t('UPLOAD_PAGE::MESSAGE')}
            </Typography>
            <Typography variant="h4" fontWeight={600}>
                {t('UPLOAD_PAGE::SUB_TITLE')}
            </Typography>
            <hr />
            <form onSubmit={formik.handleSubmit}>
                <FormControl
                    label={<>{t('EMAIL_ADDRESS')}</>}
                    caption={formik.touched.email && (formik.errors?.email as string)}
                >
                    <Input
                        tabIndex={0}
                        type="email"
                        id="email"
                        error={formik.touched.email && formik.errors.email ? true : false}
                        data-test="user-email"
                        aria-label="email-address"
                        value={state.email ? state.email : formik.values.email}
                        disabled={state.email && true}
                        onChange={formik.handleChange}
                        maxLength={254}
                    />
                </FormControl>
                <FormControl
                    label={<>{t('UPLOAD_PAGE::FIRST_NAME')}</>}
                    caption={formik.touched?.name && (formik.errors?.name as string)}
                >
                    <Input
                        tabIndex={0}
                        type="text"
                        id="name"
                        error={formik.touched?.name && formik.errors.name ? true : false}
                        data-test="name"
                        aria-label="name"
                        value={state.name ? state.name : formik.values.name}
                        disabled={state.name && true}
                        onChange={formik.handleChange}
                        maxLength={64}
                    />
                </FormControl>
                <FormControl
                    label={<>{t('UPLOAD_PAGE::LAST_NAME')}</>}
                    caption={formik.touched?.surname && (formik.errors?.surname as string)}
                >
                    <Input
                        tabIndex={0}
                        type="text"
                        id="surname"
                        error={formik.touched?.surname && formik.errors.surname ? true : false}
                        data-test="surname"
                        aria-label="surname"
                        value={state.surname ? state.surname : formik.values.surname}
                        disabled={state.surname && true}
                        onChange={formik.handleChange}
                        maxLength={128}
                    />
                </FormControl>
                <FormControl
                    label={<>{t('UPLOAD_PAGE::TELEPHONE_NUMBER')}</>}
                    caption={formik.touched?.phoneNumber && (formik.errors?.phoneNumber as string)}
                >
                    <Input
                        tabIndex={0}
                        type="text"
                        id="phoneNumber"
                        error={formik.touched?.phoneNumber && formik.errors?.phoneNumber ? true : false}
                        data-test="phoneNumber"
                        aria-label="phoneNumber"
                        value={formik.values.phoneNumber}
                        disabled={state.phoneNumber && true}
                        onChange={handlePhoneNumberInput}
                        maxLength={25}
                    />
                </FormControl>
                <FormControl label={<>{t('UPLOAD_PAGE::COMPANY_OR_ORGANISATION')}</>}>
                    <Input
                        tabIndex={0}
                        type="text"
                        id="company"
                        data-test="company"
                        aria-label="company"
                        value={formik.values?.company}
                        onChange={formik.handleChange}
                        maxLength={115}
                    />
                </FormControl>
                <FormControl label={<>{t('UPLOAD_PAGE::ADDRESS')}</>}>
                    <Input
                        tabIndex={0}
                        type="text"
                        id="address"
                        data-test="address"
                        aria-label="address"
                        value={formik.values?.address}
                        onChange={formik.handleChange}
                        maxLength={150}
                    />
                </FormControl>
                <Typography variant="h4" fontWeight={600} sx={{ marginTop: '3rem', marginBottom: '2rem' }}>
                    {t('UPLOAD_PAGE::UPLOAD_DETAILS')}
                </Typography>
                <hr />
                <FormControl
                    label={<>{t('UPLOAD_PAGE::USER_REFERENCE')}</>}
                    caption={formik.touched?.userReference && (formik.errors?.userReference as string)}
                >
                    <Input
                        tabIndex={0}
                        type="text"
                        id="userReference"
                        error={formik.touched?.userReference && formik.errors.userReference ? true : false}
                        data-test="userReference"
                        aria-label="userReference"
                        value={formik.values?.userReference.trim()}
                        onChange={handleUserReferenceInput}
                        maxLength={25}
                    />
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormControl
                        label={<>{t('UPLOAD_PAGE::EP_APPLICATION_OR_IA_NUMBER')}</>}
                        caption={formik.touched?.applicationNumber && (formik.errors?.applicationNumber as string)}
                    >
                        <Input
                            tabIndex={0}
                            type="text"
                            id="applicationNumber"
                            error={formik.touched?.applicationNumber && formik.errors.applicationNumber ? true : false}
                            data-test="applicationNumber"
                            aria-label="applicationNumber"
                            value={formik.values?.applicationNumber}
                            onChange={formik.handleChange}
                            maxLength={formik.values?.applicationNumber.startsWith('EP') ? 12 : 17}
                            ref={applicationNumberRef}
                        />
                    </FormControl>
                    <Typography sx={{ fontSize: '12px' }}>{t('UPLOAD_PAGE::PROVIDE_EP_NUM')}</Typography>
                </Box>
                <Typography variant="h4" fontWeight={600} sx={{ marginTop: '3rem', marginBottom: '2rem' }}>
                    {t('UPLOAD_PAGE::DOCUMENT_DETAILS')}
                </Typography>
                <hr />
                <Typography variant="h5" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    {t('UPLOAD_PAGE::DOCUPLOAD_MSG')}
                </Typography>
                <Typography variant="h5" sx={{ marginTop: '1rem', marginBottom: '2rem' }}>
                    {t('UPLOAD_PAGE::DOCUPLOAD_MSG1')}
                </Typography>
                <FormControl label={<>{t('UPLOAD_PAGE::DOCUMENT_DESCRIPTION')}</>}>
                    <>
                        <Input
                            tabIndex={0}
                            type="text"
                            id="documentDescription"
                            data-test="documentDescription"
                            aria-label="documentDescription"
                            value={documentDescription}
                            multiple={false}
                            onChange={getDocumentDescription}
                            maxLength={64}
                            error={noFileError}
                        />
                        {!documentDescription && (
                            <Typography sx={{ fontSize: '12px' }}>{t('UPLOAD_PAGE::PROVIDE_DESC')}</Typography>
                        )}
                    </>
                </FormControl>

                <div className="flex-grow-1">
                    <FileUpload
                        name="documentUpload"
                        ref={fileUploadRef}
                        auto
                        disabled={!documentDescription || isUploading}
                        customUpload={true}
                        mode="basic"
                        uploadHandler={(e) => fileUploadHandler(e)}
                        chooseLabel={t('UPLOAD_PAGE::UPLOAD_LABEL')}
                    />
                    <Typography sx={{ fontSize: '12px' }}>{t('UPLOAD_PAGE::ALLOWED_FILETYPES')}</Typography>
                    {isUploading && <LinearProgress />}
                    {hasError && (
                        <Alert severity="error" onClose={() => setHasError(false)}>
                            {t('ERROR_MESSAGE')}
                        </Alert>
                    )}
                    {fileSizeError && (
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem' }}>
                            <Notification
                                theme="negative"
                                onClose={() => setFileSizeError(false)}
                                text={'File size must be under 50MB!'}
                            />
                        </div>
                    )}
                    {fileError && (
                        <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setFileError(false)}>
                            {t('UPLOAD_PAGE::INVALID_FILETYPE')}
                        </Alert>
                    )}
                    {fileNameError && (
                        <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setFileNameError(false)}>
                            {t('UPLOAD_PAGE::FILE_EXISTS')}
                        </Alert>
                    )}

                    {noFileError && (
                        <Alert
                            sx={{ fontSize: '16px', marginTop: '1rem' }}
                            severity="error"
                            onClose={() => setNoFileError(false)}
                        >
                            {t('UPLOAD_PAGE::WITHOUT_FILE_SUBMIT_ERROR')}
                        </Alert>
                    )}

                    <div
                        style={{
                            paddingTop: '18px',
                        }}
                    />
                </div>

                <hr />
                <Typography variant="h5">{t('UPLOAD_PAGE::UPLOADED_DOCUMENTS_TITLE')}</Typography>
                <FileList documents={uploadedFiles} deleteFile={deleteFile} deleteOption={true} />

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 4 }}>
                    <div>
                        <Typography variant="h4" fontWeight={600}>
                            {t('UPLOAD_PAGE::SIGNATURE')}
                        </Typography>
                    </div>
                </Box>
                <hr />
                <div style={{ width: '50%' }}>
                    <FormControl
                        label={<>{t('UPLOAD_PAGE::SIGN_PARTY_NAME')}</>}
                        caption={formik.touched?.nameSign && (formik.errors?.nameSign as string)}
                    >
                        <>
                            <Input
                                tabIndex={0}
                                type="text"
                                id="nameSign"
                                data-test="nameSign"
                                aria-label="nameSign"
                                error={formik.touched?.nameSign && formik.errors?.nameSign ? true : false}
                                value={formik.values?.nameSign}
                                multiple={false}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    const currentTextStringSign = '/' + e.currentTarget.value.trim() + '/';
                                    setTextStringSign(currentTextStringSign);
                                    formik.setFieldValue('textStringSign', currentTextStringSign, false);
                                }}
                                maxLength={128}
                            />
                        </>
                    </FormControl>
                    <FormControl
                        label={<>{t('UPLOAD_PAGE::SIGN_PARTY_FUNCTION')}</>}
                        caption={formik.touched?.functionSign && (formik.errors?.functionSign as string)}
                    >
                        <>
                            <Input
                                tabIndex={0}
                                type="text"
                                id="functionSign"
                                data-test="functionSign"
                                aria-label="functionSign"
                                error={formik.touched?.functionSign && formik.errors?.functionSign ? true : false}
                                value={formik.values?.functionSign}
                                multiple={false}
                                onChange={formik.handleChange}
                                maxLength={128}
                            />
                        </>
                    </FormControl>
                    <FormControl
                        label={<>{t('UPLOAD_PAGE::SIGN_PARTY_PLACE')}</>}
                        caption={formik.touched?.placeSign && (formik.errors?.placeSign as string)}
                    >
                        <>
                            <Input
                                tabIndex={0}
                                type="text"
                                id="placeSign"
                                data-test="placeSign"
                                aria-label="placeSign"
                                value={formik.values?.placeSign}
                                multiple={false}
                                onChange={formik.handleChange}
                                maxLength={128}
                            />
                        </>
                    </FormControl>
                    <FormControl
                        label={<>{t('UPLOAD_PAGE::SIGN_PARTY_STRING_SIGN')}</>}
                        caption={formik.touched?.textStringSign && (formik.errors?.textStringSign as string)}
                    >
                        <>
                            <Input
                                tabIndex={0}
                                type="text"
                                id="textStringSign"
                                data-test="textStringSign"
                                aria-label="textStringSign"
                                error={formik.touched?.textStringSign && formik.errors?.textStringSign ? true : false}
                                value={textStringSign}
                                multiple={false}
                                onChange={formik.handleChange}
                                maxLength={130}
                                disabled={true}
                            />
                        </>
                    </FormControl>
                </div>

                <Box marginTop="3rem" textAlign="center">
                    <Button isLoading={isLoading} type="submit">
                        {t('UPLOAD_PAGE::SUBMIT')}
                    </Button>
                </Box>
            </form>
            {submitError && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Notification theme="negative" onClose={() => setSubmitError(false)} text={errMessage} />
                </div>
            )}
        </Box>
    );
};

export default UploadPage;
