import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    oneHourEmail: string;
}

const LoginConfirmationPage = ({ oneHourEmail }: Props) => {
    const { t } = useTranslation();
    return (
        <Box>
            <Typography variant="h4" fontWeight={700}>
                {t('LOGIN_CONFIRMATION_PAGE::TITLE')}
            </Typography>
            <Typography fontSize="16px" marginTop="3rem">
                {t('LOGIN_CONFIRMATION_PAGE::MESSAGE')}
            </Typography>
            <Typography fontSize="16px" marginTop="2rem">
                {oneHourEmail}
            </Typography>
            <Typography fontSize="16px" marginTop="2rem">
                {t('ONE_HOUR_VALIDATION_NOTIFICATION')}
            </Typography>
        </Box>
    );
};

export default LoginConfirmationPage;
