import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;

  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }
`;
