import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './locales/de/translation.json';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

const commonI18props: InitOptions = {
    supportedLngs: ['en', 'de', 'fr'],
    fallbackLng: 'en',
    load: 'languageOnly',
    nsSeparator: false,
    keySeparator: '::',
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
        // wait: false,
    },
};

// if (IS_DEVELOPMENT) {
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
    fr: {
        translation: translationFR,
    },
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.use(initReactI18next).init({
    resources,
    ...commonI18props,
    react: {
        useSuspense: true,
        wait: true,
    },
});

export default i18n;
