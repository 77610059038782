import axios from 'axios';
import { useAppSelector } from '../useTypedSelector';

const useAxios = () => {
    const { selectedLanguage } = useAppSelector((state) => state.app);

    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(
        async (config) => {
            config.headers['Accept-Language'] = selectedLanguage;
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    return axiosInstance;
};

export default useAxios;
