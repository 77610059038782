import { DateTime, DateTimeOptions } from 'luxon';

type DateFormatterHook = {
    formatDate: (dateString: string) => string;
    parseString: (dateStringToParse: string) => DateTime;
};

const formatTemplate: string = "LLL dd, yyyy 'at' hh:mm:ss a ZZZ";
const formatTemplateParser: string = "LLL dd, yyyy 'at' hh:mm:ss a";

const dateRegex: RegExp =
    /^\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{2},\s\d{4}\sat\s\d{2}:\d{2}:\d{2}\s(AM|PM)\b/;

const dateTimeOptions: DateTimeOptions = {
    zone: 'Europe/Amsterdam',
};

function useDateFormatter() {
    function formatDate(dateString: string): string {
        const dateTime = DateTime.fromISO(dateString, dateTimeOptions);
        return dateTime.toFormat(formatTemplate).replace('+0100', 'CET').replace('+0200', 'CEST');
    }

    function parseString(dateStringToParse: string): DateTime {
        const matchArray = dateStringToParse.match(dateRegex);
        return DateTime.fromFormat(matchArray[0], formatTemplateParser, dateTimeOptions);
    }

    const dateFormatterHook: DateFormatterHook = {
        formatDate,
        parseString,
    };
    return dateFormatterHook;
}
export default useDateFormatter;
