import React, { ReactElement, useEffect } from 'react';
import { Header } from '@epo/epods-react-components/lib/Components/Header-next';
import { useDispatch } from 'react-redux';
import { languages } from './helper';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../../features/appSlice';
import './headerStyle.css';
import { useAppSelector } from '../../../hooks/useTypedSelector';

const WrappedAppbar = (): ReactElement => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { setSelectedLanguage } = appActions;
    const { selectedLanguage } = useAppSelector((state) => state.app);

    const handleLanguageSelect = (e: any) => {
        const language = languages[e.currentTarget.ariaLabel as keyof typeof languages];
        localStorage.setItem('language', language);
        dispatch(setSelectedLanguage(localStorage.getItem('language')));
    };

    useEffect(() => {
        document.querySelector('[aria-label="switch language"]').childNodes[0].textContent = selectedLanguage
            ? selectedLanguage
            : 'En';
    }, [selectedLanguage]);

    return (
        <div
            style={{
                height: '60px',
                width: '60%',
                position: 'absolute',
                zIndex: 50,
                borderTopLeftRadius: '4px',
            }}
        >
            <Header
                id="epoHeader"
                style={{ paddingRight: '30px', borderTopRightRadius: '4px' }}
                epoLogo
                languageSelector
                onClickLanguageSelector={handleLanguageSelect}
                epoLogoSrcPath="img/logo.svg"
                epoLogoTargetUrl="https://epo.org/"
                productName="EPO Contingency Upload Service"
            />
        </div>
    );
};

export default WrappedAppbar;
