import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginState {
    isLoading?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    selectedLoginType: string | null;
}

export const initialState: LoginState = {
    selectedLoginType: null,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setSelectedLoginType: (state: any, action: PayloadAction<string>) => {
            return {
                ...state,
                selectedLoginType: action.payload,
            };
        },
    },
});

export const loginActions = loginSlice.actions;
export default loginSlice;
