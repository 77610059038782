import React, { createRef, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileList from '../UploadPage/components/FileList';
import DataField from '../../components/DataField';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { Button, Notification, Input } from '@epo/epods-react-components';
import { ArrowBack } from '@epo/epods-react-components/lib/Icons/ArrowBack';
import { BACKEND_URL } from '../../../variables';
import { appActions } from '../../../features/appSlice';
import { useNavigate } from 'react-router-dom';
import { validateEPNumber } from '../UploadPage/utility/EPValidator';
import { isPCTNumberValid } from '../UploadPage/utility/PCTValidator';
import useAxios from '../../../hooks/useAxios/useAxios';

const SubmissionDetailsPage = () => {
    const navigate = useNavigate();
    const { selectedSubmission } = useAppSelector((state) => state.submissions);
    const { isLoading } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const { setIsLoading } = appActions;
    const [hasError, setHasError] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const axios = useAxios();
    const { t } = useTranslation();
    const {
        accessCode,
        timestamp,
        name,
        surname,
        email,
        company,
        status,
        reviewMessage,
        applicationNumber,
        documents,
        address,
        userReference,
        phoneNumber,
        signingName,
        signingFunction,
        place,
        textString,
    } = selectedSubmission;

    const [applicationNumberField, setApplicationNumberField] = useState(applicationNumber);
    const [currentApplicationNumberField, setCurrentApplicationNumberField] = useState(applicationNumber);
    const [isEditingApplicationNumberField, setIsEditingApplicationNumberField] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(status);
    const [isEditingCurrentStatus, setIsEditingCurrentStatus] = useState(false);

    const downLoadPackage = () => {
        dispatch(setIsLoading(true));
        axios
            .get(`${BACKEND_URL}/internal/documents/${accessCode}/package`, { responseType: 'blob' })
            .then(({ data: blob }) => {
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = 'documents.zip';
                link.click();
                dispatch(setIsLoading(false));
            })
            .catch((err: any) => (dispatch(setIsLoading(false)), setHasError(true), setErrMessage(err.message)));
    };

    function updateSubmissionStatus(status: string) {
        dispatch(setIsLoading(true));
        axios
            .patch(
                `${BACKEND_URL}/internal/documents/${accessCode}/status`,
                {},
                {
                    params: {
                        status: status,
                    },
                },
            )
            .then(
                (res) => (
                    dispatch(setIsLoading(false)), setCurrentStatus(res.data.status), setIsEditingCurrentStatus(false)
                ),
            )
            .catch((err) => dispatch(setIsLoading(false)));
    }

    const goBackToSubmissionsList = () => {
        navigate('/submissions');
    };

    function updateApplicationNumber() {
        dispatch(setIsLoading(true));
        axios
            .patch(`${BACKEND_URL}/internal/documents/${accessCode}`, { applicationNumber: applicationNumberField })
            .then(
                (res) => (
                    setIsEditingApplicationNumberField(false),
                    setCurrentApplicationNumberField(applicationNumberField),
                    dispatch(setIsLoading(false)),
                    setCurrentStatus(res.data.status)
                ),
            )
            .catch((err) => dispatch(setIsLoading(false)));
    }

    return (
        <Box>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {t('SUBMISSION_DETAILS_PAGE::TITLE')}
            </Typography>
            <Typography sx={{ fontSize: '1.5rem', margin: '3rem 0' }}>
                {t('SUBMISSION_DETAILS_PAGE::NOTIFICATION_MESSAGE')}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('SUBMISSION_DETAILS_PAGE::TIMESTAMP_ACCESS_CODE::TITLE')}
            </Typography>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::TIMESTAMP_ACCESS_CODE::UPLOAD_TIMESTAMP',
                            value: `${timestamp}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::TIMESTAMP_ACCESS_CODE::ACCESS_CODE',
                            value: `${accessCode}`,
                        },
                    ]}
                />
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::TITLE')}
            </Typography>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::EMAIL',
                            value: `${email}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::FIRST_NAME',
                            value: `${name}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::LAST_NAME',
                            value: `${surname}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::TELEPHONE_NUMBER',
                            value: `${phoneNumber}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::COMPANY_OR_ORGANISATION',
                            value: `${company}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::CONTACT_DETAILS::ADDRESS',
                            value: `${address}`,
                        },
                    ]}
                />
            </Box>
            <div className="h-full">
                <div>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 4 }}>
                        <div>
                            <Typography variant="h4" fontWeight={600}>
                                {t('SUBMISSION_DETAILS_PAGE::STATUS_DETAILS::TITLE')}
                            </Typography>
                        </div>
                    </Box>
                </div>
            </div>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <div className="w-full grid grid-cols-3 gap-6">
                    <div>
                        <Typography fontSize="16px">{t('SUBMISSION_DETAILS_PAGE::STATUS_DETAILS::STATUS')}</Typography>
                    </div>
                    <div>{`${currentStatus}`}</div>
                    {currentStatus === 'PENDING_REVIEW' && (
                        <div className="h-full">
                            {!isEditingCurrentStatus && (
                                <Button onClick={() => setIsEditingCurrentStatus(true)}>
                                    {t('SUBMISSION_DETAILS_PAGE::STATUS_DETAILS::MANUALLY_PROCESSED')}
                                </Button>
                            )}
                            {isEditingCurrentStatus && (
                                <div className="grid grid-cols-2">
                                    <div>
                                        <Button onClick={() => updateSubmissionStatus('MANUALLY_PROCESSED')}>
                                            {t('SUBMISSION_DETAILS_PAGE::STATUS_DETAILS::CONFIRM_STATUS_CHANGE')}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setIsEditingCurrentStatus(false)}>
                                            {t('SUBMISSION_DETAILS_PAGE::STATUS_DETAILS::CANCEL_STATUS_CHANGE')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Box>
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::STATUS_DETAILS::REVIEW_TEXT',
                            value: `${reviewMessage}`,
                        },
                    ]}
                />
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('SUBMISSION_DETAILS_PAGE::PACKAGE_DETAILS::TITLE')}
            </Typography>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::PACKAGE_DETAILS::USER_REFERENCE',
                            value: `${userReference}`,
                        },
                    ]}
                />
                <div className="grid grid-cols-3 w-full py-12 h-28 gap-8">
                    <div className="h-full">
                        <Typography fontSize="16px">
                            {t('SUBMISSION_DETAILS_PAGE::PACKAGE_DETAILS::EP_APPLICATION_OR_IA_NUMBER')}
                        </Typography>
                    </div>
                    {!isEditingApplicationNumberField && <div>{applicationNumberField}</div>}
                    {isEditingApplicationNumberField && (
                        <div className="w-4/5 h-full">
                            <Input
                                tabIndex={0}
                                type="text"
                                error={
                                    !(
                                        isPCTNumberValid(applicationNumberField) ||
                                        validateEPNumber(applicationNumberField)
                                    )
                                }
                                onChange={(event) => setApplicationNumberField(event.currentTarget.value)}
                                id="applicationNumber"
                                data-test="applicationNumber"
                                aria-label="applicationNumber"
                                value={applicationNumberField}
                                maxLength={applicationNumberField.startsWith('EP') ? 12 : 17}
                            />
                        </div>
                    )}
                    <div className="h-full grid grid-cols-2">
                        <div>
                            <Button
                                onClick={() =>
                                    isEditingApplicationNumberField
                                        ? updateApplicationNumber()
                                        : setIsEditingApplicationNumberField(!isEditingApplicationNumberField)
                                }
                            >
                                {isEditingApplicationNumberField
                                    ? t('SUBMISSION_DETAILS_PAGE::CONFIM_EP_NUMBER')
                                    : t('SUBMISSION_DETAILS_PAGE::EDIT_EP_NUMBER')}
                            </Button>
                        </div>
                        <div className="h-full">
                            {isEditingApplicationNumberField && (
                                <Button
                                    onClick={() => (
                                        setIsEditingApplicationNumberField(!isEditingApplicationNumberField),
                                        setApplicationNumberField(currentApplicationNumberField)
                                    )}
                                >
                                    {t('SUBMISSION_DETAILS_PAGE::CANCEL_EDIT_EP_NUMBER')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
                {t('SUBMISSION_DETAILS_PAGE::ATTACHED_DOCUMENTS')}
            </Typography>
            <hr />
            <FileList documents={documents} deleteOption={false} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 4 }}>
                <div>
                    <Typography variant="h4" fontWeight={600}>
                        {t('SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::TITLE')}
                    </Typography>
                </div>
            </Box>
            <hr />
            <Box sx={{ margin: '2rem 0' }}>
                <DataField
                    data={[
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_NAME',
                            value: `${signingName}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_FUNCTION',
                            value: `${signingFunction}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_PLACE',
                            value: `${place}`,
                        },
                        {
                            label: 'SUBMISSION_DETAILS_PAGE::SIGNATURE_DETAILS::SIGN_PARTY_STRING_SIGN',
                            value: `${textString}`,
                        },
                    ]}
                />
            </Box>
            <hr />
            <Box sx={{ textAlign: 'center', marginTop: '4rem' }}>
                <Button isLoading={isLoading} onClick={downLoadPackage}>
                    {t('SUBMISSION_DETAILS_PAGE::DOWNLOAD_BUTTON_LABEL')}
                </Button>
            </Box>
            <Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
                <Button
                    onClick={goBackToSubmissionsList}
                    size="mini"
                    theme="tertiary-phantom"
                    startEnhancer={<ArrowBack />}
                >
                    Go back
                </Button>
            </Box>
            {hasError && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Notification theme="negative" onClose={() => setHasError(false)} text={errMessage} />
                </div>
            )}
        </Box>
    );
};

export default SubmissionDetailsPage;
